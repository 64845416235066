html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
}

[type="button"] {
  -webkit-appearance: button;
}

[type="reset"] {
  -webkit-appearance: button;
}

[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.cropper-container {
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0;
  line-height: 0;
  position: relative;
}

.cropper-container img {
  image-orientation: 0deg;
  width: 100%;
  height: 100%;
  display: block;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
}

.cropper-wrap-box, .cropper-canvas, .cropper-drag-box, .cropper-crop-box, .cropper-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.cropper-wrap-box, .cropper-canvas {
  overflow: hidden;
}

.cropper-drag-box {
  opacity: 0;
  background-color: #fff;
}

.cropper-modal {
  opacity: .5;
  background-color: #000;
}

.cropper-view-box {
  outline: 1px solid rgba(51, 153, 255, .75);
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.cropper-dashed {
  opacity: .5;
  border: 0 dashed #eee;
  display: block;
  position: absolute;
}

.cropper-dashed.dashed-h {
  border-top-width: 1px;
  border-bottom-width: 1px;
  width: 100%;
  height: 33.3333%;
  top: 33.3333%;
  left: 0;
}

.cropper-dashed.dashed-v {
  border-left-width: 1px;
  border-right-width: 1px;
  width: 33.3333%;
  height: 100%;
  top: 0;
  left: 33.3333%;
}

.cropper-center {
  opacity: .75;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.cropper-center:before, .cropper-center:after {
  content: " ";
  background-color: #eee;
  display: block;
  position: absolute;
}

.cropper-center:before {
  width: 7px;
  height: 1px;
  top: 0;
  left: -3px;
}

.cropper-center:after {
  width: 1px;
  height: 7px;
  top: -3px;
  left: 0;
}

.cropper-face, .cropper-line, .cropper-point {
  opacity: .1;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

.cropper-face {
  background-color: #fff;
  top: 0;
  left: 0;
}

.cropper-line {
  background-color: #39f;
}

.cropper-line.line-e {
  cursor: ew-resize;
  width: 5px;
  top: 0;
  right: -3px;
}

.cropper-line.line-n {
  cursor: ns-resize;
  height: 5px;
  top: -3px;
  left: 0;
}

.cropper-line.line-w {
  cursor: ew-resize;
  width: 5px;
  top: 0;
  left: -3px;
}

.cropper-line.line-s {
  cursor: ns-resize;
  height: 5px;
  bottom: -3px;
  left: 0;
}

.cropper-point {
  opacity: .75;
  background-color: #39f;
  width: 5px;
  height: 5px;
}

.cropper-point.point-e {
  cursor: ew-resize;
  margin-top: -3px;
  top: 50%;
  right: -3px;
}

.cropper-point.point-n {
  cursor: ns-resize;
  margin-left: -3px;
  top: -3px;
  left: 50%;
}

.cropper-point.point-w {
  cursor: ew-resize;
  margin-top: -3px;
  top: 50%;
  left: -3px;
}

.cropper-point.point-s {
  cursor: s-resize;
  margin-left: -3px;
  bottom: -3px;
  left: 50%;
}

.cropper-point.point-ne {
  cursor: nesw-resize;
  top: -3px;
  right: -3px;
}

.cropper-point.point-nw {
  cursor: nwse-resize;
  top: -3px;
  left: -3px;
}

.cropper-point.point-sw {
  cursor: nesw-resize;
  bottom: -3px;
  left: -3px;
}

.cropper-point.point-se {
  cursor: nwse-resize;
  opacity: 1;
  width: 20px;
  height: 20px;
  bottom: -3px;
  right: -3px;
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    width: 15px;
    height: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    opacity: .75;
    width: 5px;
    height: 5px;
  }
}

.cropper-point.point-se:before {
  content: " ";
  opacity: 0;
  background-color: #39f;
  width: 200%;
  height: 200%;
  display: block;
  position: absolute;
  bottom: -50%;
  right: -50%;
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}

.cropper-hide {
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box, .cropper-disabled .cropper-face, .cropper-disabled .cropper-line, .cropper-disabled .cropper-point {
  cursor: not-allowed;
}

[data-elm-hot="true"] {
  height: inherit;
}

/*# sourceMappingURL=index.c94c4ad8.css.map */
